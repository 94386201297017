import React, { SVGProps, useEffect, useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

interface InputProps {
  placeholder?: string;
  required?: boolean;
  type: string;
  autocomplete?: string;
  onChange?: (string) => void;
  onBlur?: (string) => void;
  onFocus?: () => void;
  value?: string;
  title?: string;
  leftComponent?: JSX.Element;
  rightComponent?: JSX.Element;
  disabled?: boolean;
  error?: boolean | string | null;
  size?: string;
  min?: number;
  max?: number;
  step?: number;
}

const Input = ({
  placeholder,
  required,
  type,
  autocomplete,
  onChange,
  value,
  title,
  leftComponent = null,
  rightComponent = null,
  disabled = false,
  size = 'w-full',
  onBlur = () => {},
  onFocus = () => {},
  error = null,
  min,
  max,
  step = 1,
}: InputProps) => {
  const [focus, setFocus] = useState(false);
  const [_value, setValue] = useState(value);
  const [eyeIcon, setEyeIcon] = useState<JSX.Element | null>();
  const [_type, setType] = useState(type);

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    if (_type === 'password') {
      const icon = (
        <EyeIcon
          onClick={() => setType('text')}
          className={'w-7 h-7 mr-4 text-gray-600'}
        />
      );
      setEyeIcon(icon);
    } else {
      const icon = (
        <EyeSlashIcon
          onClick={() => setType('password')}
          className={'w-7 h-7 mr-4 text-gray-600'}
        />
      );
      setEyeIcon(icon);
    }
  }, [_type]);

  return (
    <div
      className={clsx(
        `focus:outline-none rounded m-2 ml-0 py-2`,
        size,
        disabled && 'bg-gray-100',
      )}
    >
      {title ? (
        <div className={'text-base text-gray-600 my-1 '}>{title}</div>
      ) : null}

      <div
        className={clsx(
          `bg-white w-full shadow ring-1 rounded-md flex items-center space-between py-1 shadow-md xs:flex-col sm:flex-col md:flex-row focus-within:ring-blue-800 ring-gray-400`,
          leftComponent && 'pl-3',
          rightComponent && 'pr-3',
          error && 'ring-red-500 text-red-500',
          disabled && 'bg-gray-100',
        )}
      >
        <div className={'w-full flex items-center justify-between'}>
          {leftComponent}
          <input
            type={_type}
            className={
              'outline-none w-full flex-grow border-none focus:border-white focus:ring-0 text-lg disabled:bg-gray-100'
            }
            onFocus={(_) => {
              setFocus(true);
              onFocus();
            }}
            onBlur={(_) => {
              setFocus(false);
              onBlur(value);
            }}
            autoComplete={autocomplete}
            required={required}
            placeholder={placeholder}
            value={_value}
            disabled={disabled}
            onChange={(e) => {
              setValue(e.target.value);
              if (onChange) {
                onChange(e.target.value);
              }
            }}
            min={min}
            max={max}
            step={step}
          />

          {type === 'password' ? (
            eyeIcon
          ) : rightComponent ? (
            <div
              className={
                'xs:w-full sm:w-full md:w-1/3 lg:w-1/5 flex items-center justify-between'
              }
            >
              {rightComponent}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Input;
