import User from '../classes/User';

enum EventType {
  OPEN_HOME = 'NAVIGATE_HOME',
  OPEN_FAQ = 'NAVIGATE_FAQ',
  OPEN_SHOP = 'NAVIGATE_SHOP',
  OPEN_SPONSORSHIP = 'NAVIGATE_SPONSOR',
  OPEN_CHALLENGE_DAY = 'NAVIGATE_CHALLENGE_DAY',
  OPEN_CHALLENGE_WEEK = 'NAVIGATE_CHALLENGE_WEEK',
  OPEN_CLUB_DAY = 'NAVIGATE_CLUB_DAY',
  OPEN_CLUB_WEEK = 'NAVIGATE_CLUB_WEEK',
  OPEN_TRAINING = 'NAVIGATE_TRAINING',
  OPEN_SPACE = 'NAVIGATE_SPACE',
  OPEN_NUTRITION = 'NAVIGATE_NUTRITION',
  OPEN_SALES_PAGE = 'NAVIGATE_SALES_PAGE',
  OPEN_CHECKOUT = 'NAVIGATE_CHECKOUT',
  OPEN_COMMUNITY_CHALLENGE = 'NAVIGATE_COMMUNITY_CHALLENGE',
  OPEN_COMMUNITY_CLUB = 'NAVIGATE_COMMUNITY_CLUB',
  OPEN_CHAT = 'NAVIGATE_CHAT',

  OPEN_ACCOUNT = 'NAVIGATE_PROFILE',
  OPEN_PROGRESSION = 'NAVIGATE_PROGRES',
  OPEN_SUBSCRIPTIONS = 'NAVIGATE_SUBSCRIPTIONS',
  OPEN_CARTE = 'NAVIGATE_CARTE',
  LOGIN_AS_ADMIN = 'LOGIN_AS_ADMIN',
  LOGIN_AS_PROSPECT = 'LOGIN_AS_PROSPECT',

  SIGN_UP = 'SIGN_UP',
  COMPLETE_FORM_FRONT = 'COMPLETE_FORM_FRONT',
}

export default class EventFactory {
  name: EventType;
  properties?: Record<string, any>;

  static initSegmentClient() {
    window.analytics.load(process.env.SEGMENT_WRITE_KEY);
  }

  constructor(name: EventType) {
    this.name = name;
  }

  track(properties?: Record<string, any>) {
    this.properties = { ...properties, platform: 'web' };
    window.analytics.track(this.name, this.properties);
  }

  static identify(user: User) {
    window.analytics.identify(user.hash, {
      firstName: user.firstname,
      lastName: user.lastname,
      email: user.email.toLowerCase(),
      type: user.role,
    });
  }
}

export const Event = {
  OPEN_HOME: new EventFactory(EventType.OPEN_FAQ),
  OPEN_FAQ: new EventFactory(EventType.OPEN_FAQ),
  OPEN_SHOP: new EventFactory(EventType.OPEN_SHOP),
  OPEN_SPONSORSHIP: new EventFactory(EventType.OPEN_SPONSORSHIP),
  OPEN_CHALLENGE_DAY: new EventFactory(EventType.OPEN_CHALLENGE_DAY),
  OPEN_CHALLENGE_WEEK: new EventFactory(EventType.OPEN_CHALLENGE_WEEK),
  OPEN_CLUB_DAY: new EventFactory(EventType.OPEN_CLUB_DAY),
  OPEN_CLUB_WEEK: new EventFactory(EventType.OPEN_CLUB_WEEK),
  OPEN_TRAINING: new EventFactory(EventType.OPEN_TRAINING),
  OPEN_SPACE: new EventFactory(EventType.OPEN_SPACE),
  OPEN_NUTRITION: new EventFactory(EventType.OPEN_NUTRITION),
  OPEN_SALES_PAGE: new EventFactory(EventType.OPEN_SALES_PAGE),
  OPEN_CHAT: new EventFactory(EventType.OPEN_CHAT),
  OPEN_CHECKOUT: new EventFactory(EventType.OPEN_CHECKOUT),
  OPEN_COMMUNITY_CHALLENGE: new EventFactory(
    EventType.OPEN_COMMUNITY_CHALLENGE,
  ),
  OPEN_COMMUNITY_CLUB: new EventFactory(EventType.OPEN_COMMUNITY_CLUB),
  OPEN_ACCOUNT: new EventFactory(EventType.OPEN_ACCOUNT),
  OPEN_PROGRESSION: new EventFactory(EventType.OPEN_PROGRESSION),
  OPEN_SUBSCRIPTIONS: new EventFactory(EventType.OPEN_SUBSCRIPTIONS),
  OPEN_CARTE: new EventFactory(EventType.OPEN_SUBSCRIPTIONS),
  LOGIN_AS_ADMIN: new EventFactory(EventType.LOGIN_AS_ADMIN),
  LOGIN_AS_PROSPECT: new EventFactory(EventType.LOGIN_AS_PROSPECT),

  SIGN_UP: new EventFactory(EventType.SIGN_UP),
  COMPLETE_FORM_FRONT: new EventFactory(EventType.COMPLETE_FORM_FRONT),
};

export { EventType };
