import { Instance, TOKEN_KEY } from './API/API';
import APIError from './APIError';
import Stripe from 'stripe';
import Base from './Base';
import { ProgramLevel } from './Program';
import File from './File';
import Advancement from './Advancement';
import { CurrencyCode } from './Balance';
import Cookies from 'js-cookie';
import { addDays } from 'date-fns';
import { UserRole } from './enums/UserRole';
import { Sponsorship } from '../interfaces/Sponsorship';
import { Analytics } from '../assets/img/icons';
import { Event } from '../constants/SegmentEvents';
import Subscription = module;

export enum UserKeys {
  id,
  hash,
  gender,
  firstname,
  lastname,
  email,
  password,
  confirmPassword,
  actualPassword,
  googleId,
  facebookId,
  phoneNumber,
  birthday,
}

export enum UserGender {
  male = 'male',
  female = 'female',
  unspecified = 'unspecified',
}

class User extends Base {
  gender: UserGender;
  role: UserRole;

  firstname?: string;
  lastname?: string;
  email?: string;

  profilePicture: File;

  accountValidated: boolean;
  dateOfBirth: Date;

  phoneNumber: string;

  stripeCustomer?: string;

  password?: string;
  confirmPassword?: string;
  actualPassword?: string;
  googleId?: string;
  facebookId?: string;
  apple_id?: string;

  sponsorCode: string;
  sponsor?: User;
  lastConnexion: Date;
  advancements: Advancement[];

  subscriptions?: Subscription[];

  vdi?: Record<string, never>;

  constructor() {
    super();
  }

  async signUp(source = 'challenge') {
    try {
      const { data } = await Instance().request.post<{
        message: string;
        data: {
          jwt: string;
          name: string;
        };
      }>(
        `/v1/users`,
        {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          password: this.password,
          confirm_password: this.confirmPassword,
          google_id: this.googleId,
          apple_id: this.apple_id,
          facebook_id: this.facebookId,
          sponsor: this.sponsor,
          source,
        },
        {
          withCredentials: true,
        },
      );

      this.storeJwt(data.data.jwt);
      return data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }

  static async optin(optinData: Record<string, any>, source = 'challenge') {
    try {
      const { data } = await Instance().request.post<{
        message: string;
        data: {
          jwt: string;
          name: string;
        };
      }>(`/v1/users/optin`, optinData, {
        withCredentials: true,
      });

      new User().storeJwt(data.data.jwt);
      return data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }

  async signIn(token?: string | undefined): Promise<any> {
    try {
      const { data } = await Instance().request.patch<{
        message: string;
        data: {
          jwt: string;
          name: string;
        };
      }>(`/v1/users/login`, {
        email: this.email,
        password: this.password,
        google_id: this.googleId,
        facebook_id: this.facebookId,
        apple_id: this.apple_id,
        token,
      });
      this.storeJwt(data.data.jwt);

      return data;
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }

  initials(): string {
    const array = this.firstname?.split(/[\s-]/);
    this.lastname ? array.push(this.lastname) : null;
    return array.map((w) => w.charAt(0)).join('');
  }

  fullName(): string {
    return `${this.firstname} ${this.lastname || ''}`;
  }

  storeJwt(token: string): void {
    window.localStorage.setItem(TOKEN_KEY, token);
    Cookies.set('_rj_auth_token', token, {
      domain: process.env.COOKIE_DOMAIN,
      expires: addDays(new Date(), 200),
    });
  }

  static logout() {
    window.localStorage.removeItem(TOKEN_KEY);
    Cookies.remove('_rj_auth_token');
  }

  static getToken() {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  async validate(token: string) {
    try {
      await Instance(true).request.get<{
        message: string;
      }>(`/v1/users/validate`, { params: { token } });
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }

  async getCurrent() {
    try {
      const { data } = await Instance(true).request.get<{
        message: string;
        data: {
          user: User;
          jwt: string;
        };
      }>(`/v1/users/me`);

      const apiUser = data.data.user;
      this.storeJwt(data.data.jwt);

      Object.keys(this).forEach((key) => {
        this[key] = apiUser[key];
      });
      this.advancements = apiUser.advancements;
      this.createdAt = new Date(apiUser.createdAt);
      this.updatedAt = new Date(apiUser.updatedAt);
    } catch (e) {
      if (!e.response) throw e;
      if (e.response.status >= 400 && e.response.status < 600) {
        User.logout();
      }
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }

  static async getAll(skip: number) {
    try {
      const { data } = await Instance(true).request.get<{
        users: User[];
      }>(`/v1/users`, {
        params: {
          skip,
        },
      });

      return data.users.map((u) => Object.assign(new User(), u));
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }

  static async getOne(hash: string, small = false, withToken = false) {
    const { data } = await Instance(!small).request.get<{
      success: boolean;
      user: User;
      jwt: string;
    }>(small ? `/v1/users/${hash}/simple` : `/v1/users/${hash}`, {
      params: {
        withToken,
      },
    });
    return withToken ? data.jwt : data.user;
  }

  async identify() {
    const { status } = await Instance(true).request.patch<{
      success: boolean;
      user: User;
      jwt: string;
    }>(`/v1/users/${this.hash}/identify`);
    return status === 200;
  }

  async updateDefaultCard(paymentMethod: string) {
    try {
      const { data } = await Instance(true).request.patch<{
        message: string;
        success: boolean;
      }>(`/v1/users/${this.hash}/cards/${paymentMethod}`);

      return data.message;
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }

  async checkAccount(): Promise<CheckAccountResponse> {
    try {
      const { data } = await Instance(false).request.get<CheckAccountResponse>(
        `/v1/users/check-account`,
        {
          params: {
            email: this.email,
            source: 'Sign-up challenge',
          },
        },
      );
      return data;
    } catch (error) {
      if (error.response.status === 409) {
        return {
          success: false,
          error: 'Cet utilisateur a déjà un compte sur Rester Jeune',
        };
      }
    }
  }

  /* Checkout */
  async sendConfirmationEmail() {
    try {
      await Instance(true).request.get<{
        message: string;
        status: boolean;
      }>(`/v1/users/${this.hash}/send_confirmation_email`);
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }

  /* Update profile */
  async update() {
    const { data } = await Instance(true).request.patch<{
      success: boolean;
      user: User;
    }>(`/v1/users/${this.hash}`, { ...this });

    return data.user;
  }

  /* Update profile */
  async deactivate() {
    const { data } = await Instance(true).request.patch<{
      success: boolean;
      message: string;
    }>(`/v1/users/${this.hash}/deactivate`, { ...this });

    return data;
  }

  async changePassword(admin?: string) {
    const { data } = await Instance(true).request.patch<{
      success: boolean;
      jwt: string;
    }>(`/v1/users/${this.hash}/password`, {
      currentPassword: this.actualPassword,
      newPassword: this.password,
      confirmPassword: this.confirmPassword,
      admin,
    });
    if (data.jwt) {
      this.storeJwt(data.jwt);
    }
  }

  /* Password */

  async createPassword(confirm: string): Promise<any> {
    try {
      await Instance(true).request.patch<{
        message: string;
        status: boolean;
      }>(`/v1/users/${this.hash}/create-password`, {
        current: this.password,
        confirm,
      });
    } catch (e) {
      const error = new Error();
      error.message = e.response.data.message;
      throw error;
    }
  }

  async requestNewPassword(): Promise<any> {
    const response = await Instance().request.patch(
      `/v1/users/request-password`,
      {
        email: this.email,
      },
    );
    return response.status;
  }

  async resetPassword(reset_token: string): Promise<any> {
    const response = await Instance().request.patch(
      `/v1/users/reset-password`,
      {
        password: this.password,
        password_confirmation: this.confirmPassword,
        reset_token,
      },
    );
  }

  /* Training */
  async createTraining(
    answers?: { step: number; selected: number; value: string }[],
  ) {
    const { data } = await Instance(true).request.post<{
      message: string;
    }>(`/v1/progressions`, {
      answers,
    });
    Event.COMPLETE_FORM_FRONT.track();
    return data;
  }

  async updateTraining(level?: ProgramLevel) {
    const { data } = await Instance(true).request.post<{
      message: string;
    }>(`/v1/progressions`, {
      level: level,
    });
    Event.COMPLETE_FORM_FRONT.track();
    return data;
  }

  /* Balance */
  async getBalance() {
    const { data } = await Instance(true).request.get<{
      operations: any[];
      balance: number;
    }>(`/v1/users/${this.hash}/balance`);

    return data;
  }

  /* Payments */
  async getInvoices() {
    const { data } = await Instance(true).request.get<{
      invoices: any[];
      success: boolean;
    }>(`/v1/users/${this.hash}/payments/invoices`);

    return data;
  }
  async getPayments(): Promise<Stripe.Charge[]> {
    const { data } = await Instance(true).request.get<{
      data: { payments: Stripe.Charge[] };
      success: boolean;
    }>(`/v1/users/${this.hash}/payments`);

    return data.data.payments;
  }

  /* Get Payment Methods */
  async getPaymentMethods() {
    const { data } = await Instance(true).request.get<{
      cards: Stripe.PaymentMethod[];
      paypal: Stripe.PaymentMethod[];
      default_card: string;
      success: boolean;
    }>(`/v1/users/${this.hash}/payments/payment-methods`);

    return data;
  }

  /* Sponsorship */
  async getSponsoredUser(): Promise<Sponsorship[]> {
    const { data } = await Instance(true).request.get<{
      data: Sponsorship[];
    }>(`/v1/users/${this.hash}/sponsorships`);
    return data.data;
  }

  /* Admin */

  async getEmails(): Promise<Record<string, any>[]> {
    const { data } = await Instance(true).request.get<{
      emails: Record<string, any>[];
    }>(`/v1/users/${this.hash}/emails`);
    return data.emails;
  }

  async delete(): Promise<boolean> {
    const { status } = await Instance(true).request.delete(
      `/v1/users/${this.hash}`,
    );
    return status === 204;
  }
}

export default User;
